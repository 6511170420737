import React, { useRef, useEffect, useMemo, useCallback, useState } from 'react';
import { Textfit } from 'react-textfit';
import classnames from 'classnames';

import { getCloudinaryURL } from 'utils/media';

import classes from './GallerySpaceFiller.module.scss';

const GallerySpaceFiller = ({ text = '', image, transformations }) => {
  const [isVisible, setIsVisible] = useState(false);
  const observerElem = useRef(null);

  const handleObserver = useCallback(
    entries => {
      const [target] = entries;

      if (!isVisible && target.isIntersecting) {
        setIsVisible(true);
      }
    },
    [isVisible],
  );

  useEffect(() => {
    const element = observerElem.current;
    const option = { threshold: 0 };
    const observer = new IntersectionObserver(handleObserver, option);

    observer.observe(element);

    return () => observer.unobserve(element);
  }, [handleObserver]);

  const generateRandomColor = useCallback(() => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);

    if (/^[0-9A-F]{6}$/i.test(randomColor)) {
      return randomColor;
    }

    return generateRandomColor();
  }, []);

  const reduceAlpha = useCallback((color, opacity) => {
    const OPACITY = Math.round(opacity * 255);

    return color + OPACITY.toString(16).toUpperCase();
  }, []);

  const { rootStyle, wrapperStyle } = useMemo(() => {
    if (!image) {
      const color1 = generateRandomColor();
      const color2 = generateRandomColor();

      return {
        rootStyle: {
          backgroundImage: `linear-gradient(45deg, #${reduceAlpha(color1, 0.1)}, #${reduceAlpha(color2, 0.1)})`,
        },
        wrapperStyle: {},
      };
    }

    const { fallback: wrapperImage } = getCloudinaryURL({ src: image, transformations });

    return {
      rootStyle: {},
      wrapperStyle: {
        ...(isVisible && { backgroundImage: `url(${wrapperImage})` }),
      },
    };
  }, [isVisible, image, generateRandomColor, reduceAlpha, transformations]);

  return (
    <div className={classes.root} style={rootStyle} ref={observerElem}>
      <div className={classes.wrapper} style={wrapperStyle}>
        <Textfit mode="multi" className={classnames(classes.word, { [classes.text]: !image })} max={500}>
          {text}
        </Textfit>
      </div>
    </div>
  );
};

export default GallerySpaceFiller;
