/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';

import SectionBlock from 'components/Globals/SectionBlock';
import InfiniteListPage, { PageLoading } from 'components/Globals/Layout/InfiniteListPage';
import PreviewEntityBlock from 'components/Globals/PreviewEntityBlock';
import Loader from 'components/uiLibrary/Loader';
import NoResult from 'components/Globals/NoResult';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import { getProductionTitleWorkNames } from 'utils/productions';
import { transformUpcomingDateFilters, transformPastDateFilters } from 'utils/globals/filters';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';

import queries from 'containers/Media/queries';
import {
  TP,
  ENTITY_TYPE,
  ENTITY_MAIN_TABS,
  ENTITY_DETAILS_TAB,
  PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
  UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
  MEDIA_SHOWREEL_OPTIONS,
  SORT_OPTION_VALUES,
} from 'constants/index';

import VideoCard from '../VideoCard';
import DigitalVideoListing from '../DigitalVideoListing';
import classes from './VideoListing.module.scss';

export const VIDEO_SECTION_TYPES = {
  DIGITAL: 'digital',
  FEATURED: 'featured',
  PAST: 'past',
  UPCOMING: 'upcoming',
  PRODUCTION: 'production',
  INTRO_SHOWREEL: 'introshowreel',
  AUDITION_SHOWREEL: 'audionshowreel',
};

const FEATURED_SECTION_TITLES = {
  [ENTITY_TYPE.ARTIST]: `${TP}.FN_PROFILE_VIDEOS_SECTION`,
  [ENTITY_TYPE.ORGANIZATION]: `${TP}.FN_ORGANIZATION_VIDEOS_SECTION_TITLE`,
  [ENTITY_TYPE.PRODUCTION]: `${TP}.FN_TAB_VIDEOS`,
};

const VideoGroup = ({ videos, fullWidthCard = false, withProductionInfo = false, trackingData }) => (
  <div
    className={classnames(classes.group, {
      [classes.column_single]: !fullWidthCard && videos?.length === 1,
      [classes.column_double]: !fullWidthCard && videos?.length === 2,
      [classes.column_triple]: !fullWidthCard && videos?.length > 2,
      [classes.column_full]: fullWidthCard,
    })}
  >
    {videos?.map(video => (
      <VideoCard
        key={video?.id}
        data={video}
        withProductionInfo={video?.production?.id && withProductionInfo}
        fullWidth={fullWidthCard}
        trackingData={{ ...trackingData, entityId: video?.id }}
      />
    ))}
  </div>
);

const VIDEO_SECTION_SORT_ORDER = {
  [VIDEO_SECTION_TYPES.PAST]: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
  [VIDEO_SECTION_TYPES.UPCOMING]: UPCOMING_PRODUCTIONS_MEDIA_SORT_ORDER,
  [VIDEO_SECTION_TYPES.PRODUCTION]: PAST_PRODUCTIONS_MEDIA_SORT_ORDER,
};

const ProductionVideos = ({
  title,
  type,
  limit,
  distinctLimit,
  infinite,
  sectionProps = {},
  isOverview = false,
  trackingData,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entity, entityType, entityId, filterParams, navigate, mainPath, hasAppliedFilters } = usePageContext();
  const isTabletLayout = useMediaQuery('(max-width: 1280px) and (min-width: 925px)') || !mainPath;
  const isMobileLayout = useMediaQuery('(max-width: 925px) and (min-width: 600px)') || !mainPath;
  const isFullWidthCard = useMediaQuery('(max-width: 800px)');
  const { isMobile } = useDeviceTypeLayouts();
  const filters = useMemo(() => {
    if (type === VIDEO_SECTION_TYPES.PRODUCTION) {
      return {
        ...filterParams,
        sort: SORT_OPTION_VALUES.CUSTOM_DATE,
      };
    }

    if (type === VIDEO_SECTION_TYPES.PAST) {
      return transformPastDateFilters(filterParams);
    }

    return transformUpcomingDateFilters(filterParams);
  }, [type, filterParams]);

  const linkProps = navigate.getLinkProps({ entityType, entity, path: ENTITY_MAIN_TABS.VIDEOS });

  const renderMaxCount = isMobileLayout ? 1 : isTabletLayout ? 2 : 3;

  const mediaCount = useMemo(() => {
    if (mainPath || hasAppliedFilters) {
      return null;
    }

    return entity?.stats?.media?.videos?.total;
  }, [mainPath, hasAppliedFilters, entity]);

  return (
    <InfiniteListPage
      query={queries.getProductionVideos({
        entityType,
        entityId,
        filters,
        limit,
        distinctLimit,
        sort: VIDEO_SECTION_SORT_ORDER[type],
      })}
      disableQueryUpdate
      updateAppliedFilters={!!mainPath}
      disabled={!infinite}
    >
      {({ count, isLoading, pages }) => (
        <SectionBlock
          title={title}
          count={mediaCount || count}
          linkProps={{
            ...linkProps,
            ...(isOverview && { title: t(`${TP}.FN_VIEW_ALL_VIDEOS`) }),
          }}
          enableSeeAll={!mainPath}
          enableViewAllButton={isOverview}
          seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
          {...sectionProps}
        >
          {pages?.map(({ data: pageData }) => (
            <>
              {pageData?.map(({ production, data: videos, total: totalCount = 0 }, index) => {
                const name = getProductionTitleWorkNames(production);
                const prodTitle = name?.customName || name?.workNames?.[0];
                const showCtaLabel = isMobile ? totalCount >= 1 : totalCount > 3;

                return (
                  <PreviewEntityBlock
                    key={`${production?.id}_${index}`}
                    production={production}
                    linkProps={navigate.getLinkProps({
                      entityType: ENTITY_TYPE.PRODUCTION,
                      entity: production,
                      path: ENTITY_MAIN_TABS.VIDEOS,
                      title: t(`${TP}.FN_VIEW_MORE_VIDEOS_FROM`, {
                        entityName: prodTitle,
                      }),
                    })}
                    {...(showCtaLabel && {
                      ctaLabel: t(`${TP}.FN_VIEW_X_MORE_VIDEOS`),
                    })}
                    disabled={!mainPath}
                    hideLinkProps={totalCount < renderMaxCount}
                    trackingData={trackingData}
                    ctaTrackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.VIEW_MORE_CTA }}
                  >
                    <VideoGroup
                      videos={videos?.slice(0, renderMaxCount)}
                      fullWidthCard={!mainPath || isFullWidthCard}
                      withProductionInfo
                      trackingData={trackingData}
                    />
                  </PreviewEntityBlock>
                );
              })}
            </>
          ))}
          {!isLoading && !count && <NoResult title={t(`${TP}.NO_RESULTS`, { ns: 'NS_APP_GLOBALS' })} />}
          <PageLoading defaultLoader />
        </SectionBlock>
      )}
    </InfiniteListPage>
  );
};

const FeaturedVideos = ({ entity, title, limit = 3, sectionProps = {}, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityType, entityId, filterParams, navigate, mainPath, hasAppliedFilters } = usePageContext();
  const { data, isLoading } = useQuery(
    queries.getFeaturedVideos({ entityId, entityType, filters: filterParams, limit }),
  );
  const { data: videos, total } = data || {};
  const isMobileLayout = useMediaQuery('(max-width: 925px) and (min-width: 600px)') || !mainPath;
  const renderFullWidth = useMediaQuery('(max-width: 800px)');

  const linkProps = useMemo(() => {
    let path = ENTITY_MAIN_TABS.VIDEOS;

    if (mainPath === ENTITY_MAIN_TABS.VIDEOS) {
      path += `/${ENTITY_DETAILS_TAB.GENERAL}`;
    }

    return navigate.getLinkProps({ entityType, entity, path, pro: false });
  }, [navigate, mainPath, entityType, entity]);

  const mediaCount = useMemo(() => {
    if (mainPath || hasAppliedFilters) {
      return null;
    }

    return entity?.stats?.media?.videos?.total;
  }, [mainPath, hasAppliedFilters, entity]);

  return (
    <SectionBlock
      title={title}
      count={mediaCount || total}
      linkProps={linkProps}
      enableSeeAll={isMobileLayout}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
      {...sectionProps}
    >
      <PreviewEntityBlock
        linkProps={linkProps}
        ctaLabel={t(`${TP}.FN_VIEW_X_MORE_VIDEOS`, {
          count: total,
        })}
        disabled={isMobileLayout}
        hideLinkProps={total <= 3}
        trackingData={trackingData}
        ctaTrackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.VIEW_MORE_CTA }}
      >
        <VideoGroup videos={videos} fullWidthCard={!mainPath || renderFullWidth} trackingData={trackingData} />
      </PreviewEntityBlock>
      {!isLoading && !total && <NoResult title={t(`${TP}.NO_RESULTS`, { ns: 'NS_APP_GLOBALS' })} />}
      {isLoading && <Loader />}
    </SectionBlock>
  );
};

const ShowreelVideos = ({ entity, title, limit, sectionProps = {}, showreelTypeId, trackingData }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityType, entityId, navigate, mainPath } = usePageContext();
  const { data, isLoading } = useQuery(queries.getShowreelVideos({ id: showreelTypeId, entityType, entityId, limit }));

  const { data: videos, total } = data || {};
  const isTabletLayout = useMediaQuery('(max-width: 1280px) and (min-width: 925px)') || !mainPath;
  const isMobileLayout = useMediaQuery('(max-width: 925px) and (min-width: 600px)') || !mainPath;
  const renderFullWidth = useMediaQuery('(max-width: 800px)');

  const linkProps = useMemo(() => {
    let path = ENTITY_MAIN_TABS.VIDEOS;

    if (mainPath === ENTITY_MAIN_TABS.VIDEOS) {
      path += `/${
        showreelTypeId === MEDIA_SHOWREEL_OPTIONS.INTRO.id
          ? ENTITY_DETAILS_TAB.INTRO_SHOWREEL
          : ENTITY_DETAILS_TAB.AUDITION_SHOWREEL
      }`;
    }

    return navigate.getLinkProps({ entityType, entity, path, pro: false });
  }, [navigate, mainPath, entityType, entity, showreelTypeId]);

  const renderMaxCount = isMobileLayout ? 1 : isTabletLayout ? 2 : 3;

  return (
    <SectionBlock
      title={title}
      count={total}
      linkProps={linkProps}
      enableSeeAll={isMobileLayout}
      {...sectionProps}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
    >
      <PreviewEntityBlock
        linkProps={linkProps}
        ctaLabel={t(`${TP}.FN_VIEW_X_MORE_VIDEOS`, {
          count: total > renderMaxCount ? total - renderMaxCount : null,
        })}
        disabled={isMobileLayout}
        hideLinkProps={total <= 3}
        trackingData={trackingData}
        ctaTrackingData={{ ...trackingData, subComponent: SUB_COMPONENTS.VIEW_MORE_CTA }}
      >
        <VideoGroup
          videos={videos?.slice(0, renderMaxCount)}
          fullWidthCard={!mainPath || renderFullWidth}
          trackingData={trackingData}
        />
      </PreviewEntityBlock>
      {!isLoading && !total && <NoResult title={t(`${TP}.NO_RESULTS`, { ns: 'NS_APP_GLOBALS' })} />}
      {isLoading && <Loader />}
    </SectionBlock>
  );
};

const DigitalVideos = ({ entity, title, limit: _limit, sectionProps = {}, trackingData }) => {
  const { entityType, entityId, filterParams, navigate } = usePageContext();
  const { isMobile, isTablet } = useDeviceTypeLayouts();
  const limit = isMobile ? 2 : isTablet ? 3 : _limit || 7;
  const { data, isLoading } = useQuery(
    queries.getDigitalVideos({ entityId, entityType, filters: filterParams, limit }),
  );
  const { data: videos, total } = data || {};

  const linkProps = useMemo(
    () =>
      navigate.getLinkProps({
        entityType,
        entity,
        path: `${ENTITY_MAIN_TABS.VIDEOS}/${ENTITY_DETAILS_TAB.DIGITAL}`,
        pro: false,
      }),
    [entity, entityType, navigate],
  );

  const totalCount = useMemo(() => {
    const productionMedia = entity?.stats?.media?.videos?.digital?.total || 0;

    return Math.min(productionMedia, limit);
  }, [entity, limit]);

  return (
    <SectionBlock
      title={title}
      count={total}
      linkProps={linkProps}
      enableSeeAll
      {...sectionProps}
      seeAllTrackingData={{ ...trackingData, component: COMPONENTS.SEE_ALL_CTA }}
    >
      <DigitalVideoListing data={videos} count={totalCount} isLoading={isLoading} trackingData={trackingData} />
    </SectionBlock>
  );
};

const VideoListing = ({
  title,
  entity,
  type,
  limit,
  distinctLimit,
  infinite = true,
  sectionProps,
  isOverview,
  trackingData,
}) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { entityType } = usePageContext();

  if (type === VIDEO_SECTION_TYPES.DIGITAL) {
    return (
      <DigitalVideos
        title={title || t(`${TP}.FN_WATCH_ONLINE_LS_VOD`)}
        entity={entity}
        limit={limit}
        sectionProps={sectionProps}
        trackingData={trackingData}
      />
    );
  }

  if (type === VIDEO_SECTION_TYPES.FEATURED) {
    return (
      <FeaturedVideos
        title={title || t(FEATURED_SECTION_TITLES[entityType])}
        entity={entity}
        limit={limit}
        sectionProps={sectionProps}
        trackingData={trackingData}
      />
    );
  }

  if ([VIDEO_SECTION_TYPES.INTRO_SHOWREEL, VIDEO_SECTION_TYPES.AUDITION_SHOWREEL].includes(type)) {
    const props =
      type === VIDEO_SECTION_TYPES.INTRO_SHOWREEL
        ? { title: t(`${TP}.FN_ARTIST_INTRO_SHOWREEL_SECTION`), showreelTypeId: MEDIA_SHOWREEL_OPTIONS.INTRO.id }
        : { title: t(`${TP}.FN_ARTIST_AUDITION_SHOWREEL_SECTION`), showreelTypeId: MEDIA_SHOWREEL_OPTIONS.AUDITION.id };

    return (
      <ShowreelVideos
        entity={entity}
        limit={limit}
        sectionProps={sectionProps}
        {...props}
        trackingData={trackingData}
      />
    );
  }

  if (
    entityType !== ENTITY_TYPE.PRODUCTION &&
    [VIDEO_SECTION_TYPES.PAST, VIDEO_SECTION_TYPES.UPCOMING, VIDEO_SECTION_TYPES.PRODUCTION].includes(type)
  ) {
    let sectionTitle = `${TP}.FN_PRODUCTION_VIDEOS`;

    if (!title) {
      if (type === VIDEO_SECTION_TYPES.PAST) {
        sectionTitle = `${TP}.FN_PAST_PRODUCTION_VIDEOS`;
      } else if (type === VIDEO_SECTION_TYPES.UPCOMING) {
        sectionTitle = `${TP}.FN_UPCOMING_PRODUCTION_VIDEOS`;
      }
    }

    return (
      <ProductionVideos
        title={title || t(sectionTitle)}
        type={type}
        limit={limit}
        distinctLimit={distinctLimit}
        infinite={infinite}
        sectionProps={sectionProps}
        isOverview={isOverview}
        trackingData={trackingData}
      />
    );
  }

  return null;
};

VideoListing.propTypes = {
  type: PropTypes.oneOf([
    VIDEO_SECTION_TYPES.DIGITAL,
    VIDEO_SECTION_TYPES.FEATURED,
    VIDEO_SECTION_TYPES.PAST,
    VIDEO_SECTION_TYPES.UPCOMING,
    VIDEO_SECTION_TYPES.UPCOMING,
    VIDEO_SECTION_TYPES.INTRO_SHOWREEL,
    VIDEO_SECTION_TYPES.AUDITION_SHOWREEL,
  ]),
  limit: PropTypes.number,
};

export default VideoListing;
