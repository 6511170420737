import React from 'react';
import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useTheme, { THEMES } from 'components/Globals/Layout/ThemeProvider';

import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import classes from './EntityViewAllCard.module.scss';

const EntityViewAllCard = ({ title, total, linkProps, styles }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { theme } = useTheme();
  const isLightTheme = theme === THEMES.LIGHT;

  return (
    <LinkButton
      variant="text"
      isLink
      {...linkProps}
      styles={{
        root: classnames(classes.root, {
          [styles?.root]: !!styles?.root,
        }),
      }}
    >
      {total ? (
        <Typography
          size="24"
          className={classnames(classes.root__count, {
            [styles?.count]: !!styles?.count,
          })}
        >
          {total}
        </Typography>
      ) : null}
      <div
        className={classnames(classes.root__titleSection, {
          [styles?.titleSection]: !!styles?.titleSection,
        })}
      >
        <Typography color={isLightTheme ? 'primary' : 'white'} weight="medium" size="12">
          {title}
        </Typography>
        <span>
          <Typography
            color={isLightTheme ? 'primary' : 'tertiary'}
            size="12"
            className={classnames(classes.viewAll, { [styles?.viewAll]: !!styles?.viewAll })}
          >
            {t(`${TP}.VIEW_ALL`)}
          </Typography>
          <SpriteIcon
            icon="chevron_right"
            size="16"
            className={classnames(classes.icon, { [styles?.icon]: !!styles?.icon })}
          />
        </span>
      </div>
    </LinkButton>
  );
};

export default EntityViewAllCard;
