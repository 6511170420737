import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from '../DynamicTag';

const ParseHtml = ({ className, children, tagName = 'div', ...additionalProps }) => (
  // eslint-disable-next-line react/no-danger
  <Tag className={className} dangerouslySetInnerHTML={{ __html: children }} {...additionalProps} tagName={tagName} />
);

ParseHtml.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
};

export default ParseHtml;
